import { SvgIconProps } from "@material-ui/core";
import React from "react";
export function RareDevLogo(props: SvgIconProps) {
    return <svg {...props}  viewBox="0 0 1970 321" fill="none" xmlns="http://www.w3.org/2000/svg">
        
            <path d="M192.471 8.97864C159.253 8.97864 129.843 50.724 110.61 82.1234L109.666 83.6667H103.21V8.16285C72.8509 20.1748 42.4481 28.3747 5.67181 34.4899V44.556C23.3168 47.1552 34.6184 49.4443 41.1286 57.1394C47.5969 64.7829 48.6882 76.4828 48.6882 99.8179V243.049C48.6882 265.586 47.2116 279.366 39.4185 287.63C32.3486 295.126 21.3753 297.609 0 299.853V312.145H161.353V299.919C134.338 297.737 120.707 295.309 112.729 287.76C103.851 279.36 103.21 265.264 103.21 243.049V119.035L103.452 118.447C118.433 81.9307 140.407 59.2457 160.799 59.2457C174.555 59.2457 183.28 64.7549 193.703 74.2722C197.472 77.6214 202.685 78.0713 209.163 75.5766C223.12 68.991 231.786 55.8297 231.786 41.2024C231.786 25.641 215.988 8.97864 192.471 8.97864Z" fill="#272625" />
            <path d="M437.253 259.039L436.307 259.985C423.053 273.238 399.418 283.621 382.499 283.621C357.561 283.621 331.055 264.1 331.055 227.921C331.055 197.304 352.44 183.865 375.549 173.904C389.748 167.491 418.356 154.149 432.58 147.037L437.253 144.7V259.039ZM519.112 279.366C510.994 279.366 504.104 275.778 499.184 268.991C493.641 261.343 490.83 249.911 490.83 235.012V110.69C490.83 70.1587 481.153 44.754 458.479 25.7615C443.951 14.674 425.015 9.02915 402.189 8.97856C365.275 16.1829 318.181 41.4015 296.464 57.0576C281.285 67.5273 275.115 74.9749 275.115 82.8003C275.115 96.9636 291.405 110.297 304.03 110.297C314.162 110.297 320.032 105.885 323.636 95.5473C330.719 69.9639 337.116 56.0212 345.709 47.4284C353.684 38.9197 365.096 34.1917 377.772 34.1917C395.658 34.1917 410.622 41.3498 421.043 54.8911C431.648 68.6713 437.253 88.9477 437.253 113.526V127.317L436.107 128.284C418.252 143.35 359.629 163.27 326.274 173.803C288.282 185.998 271.333 205.898 271.333 238.321C271.333 278.559 302.659 320.18 355.082 320.18C370.079 320.18 394.21 304.237 417.546 288.819C423.218 285.072 429.083 281.196 434.66 277.664L438.242 275.396L439.477 279.451C442.704 290.059 446.688 298.863 455.629 307.358C462.788 314.515 476.737 319.915 488.468 320.17L547.259 285.613L542.457 273.128C534.416 277.118 526.07 279.366 519.112 279.366Z" fill="#272625" />
            <path d="M767.282 8.97864C734.063 8.97864 704.653 50.724 685.42 82.1234L684.477 83.6667H678.02V8.16285C647.662 20.1748 617.259 28.3747 580.482 34.4899V44.556C598.126 47.1552 609.428 49.4443 615.939 57.1394C622.408 64.7829 623.498 76.4828 623.498 99.8179V243.049C623.498 265.586 622.02 279.366 614.228 287.63C607.159 295.126 596.185 297.609 574.81 299.853V312.145H736.163V299.919C709.147 297.737 695.517 295.309 687.539 287.76C678.661 279.36 678.02 265.264 678.02 243.049V119.035L678.261 118.447C693.243 81.9296 715.217 59.2457 735.61 59.2457C749.367 59.2457 758.091 64.7549 768.513 74.2722C772.283 77.6225 777.495 78.0702 783.973 75.5766C797.931 68.991 806.596 55.8297 806.596 41.2024C806.596 25.641 790.799 8.97864 767.282 8.97864Z" fill="#272625" />
            <path d="M1010.38 118.171C971.075 119.592 933.079 119.592 896.33 119.592H892.499L893.149 115.815C897.894 88.245 907.707 65.5782 921.527 50.2665C934.368 36.0396 950.011 28.5199 966.765 28.5199H967.236C1008.02 28.5199 1030.03 66.2282 1030.03 101.709C1030.03 115.549 1020.09 117.798 1010.38 118.171ZM983.781 8.97856H983.309C947.683 8.97856 909.958 27.8925 879.806 60.8718C851.388 91.6556 835.744 132.848 835.744 176.869C835.744 248.085 881.885 320.18 970.073 320.18C998.278 320.18 1044.96 302.774 1084.61 253.894L1076.5 243.521C1051.56 265.725 1025.06 276.53 995.6 276.53C943.964 276.53 891.222 236.229 889.793 146.195L889.744 143.027L892.909 142.917C973.729 140.097 1061.64 133.007 1074.43 131.148C1085.08 128.755 1088.33 124.289 1088.33 112.109C1088.33 57.1706 1039.47 8.97856 983.781 8.97856Z" fill="#272625" />
            <path d="M1206.74 37.9375V292.2" stroke="#434C27" strokeWidth="4.03591" strokeLinecap="round" />
            <path d="M1333.98 314V13.9003H1406.17C1488.09 13.9003 1538.78 52.8321 1538.78 163.139C1538.78 274.663 1486.87 314 1406.17 314H1333.98ZM1381.84 271.418H1411.44C1467.4 271.418 1490.52 241.814 1490.52 163.139C1490.52 86.8975 1467.4 57.6986 1411.03 57.6986H1381.84V271.418ZM1570.53 314V13.9003H1733.15V58.1042H1618.38V135.157H1701.52V178.144H1618.38V271.013H1739.23V314H1570.53ZM1830.78 315.217L1745.21 13.9003H1793.06L1833.21 165.978C1841.73 197.204 1847.81 221.942 1854.71 251.547H1855.52C1862.01 221.942 1868.49 197.61 1877.01 166.789L1917.56 13.9003H1962.17L1876.6 315.217H1830.78Z" fill="#434C27" />

       
    </svg>

}

