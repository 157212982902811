import React from 'react';
import "@stoplight/elements/styles.min.css";
import Styles from './StoplightApi.module.css'

// @ts-ignore  
import("https://unpkg.com/@stoplight/elements/web-components.min.js")

export default function StoplightApi({url}:{url?:string}) {
    if (!url) return <div>loading...</div>
    return <div className={Styles.elementContainer}>
        <elements-api apiDescriptionUrl={url} router="hash" />

    </div>


}
