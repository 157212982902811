
import { SignInProviderConfig, UserIdentity, Progress } from "@backstage/core-components";
import { BackstageIdentityResponse, SignInPageProps, useApi, } from "@backstage/core-plugin-api";

import React, { ComponentType, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useMountEffect } from '@react-hookz/web';
import { Typography, Button, useTheme, makeStyles } from "@material-ui/core";

import { RareDevLogo } from "../icons";
type CommonSignInPageProps = SignInPageProps & {
    /**
     * Error component to be rendered instead of the default error panel in case
     * sign in fails.
     */
    ErrorComponent?: ComponentType<{ error?: Error }>;
};



type SingleSignInPageProps = CommonSignInPageProps & {
    provider: SignInProviderConfig;
    auto?: boolean;
};

export const RareSignInPage = ({
    provider,
    auto,
    onSignInSuccess,
    ErrorComponent,
}: SingleSignInPageProps) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',    
            justifyContent: 'center',
            alignItems: 'center',

        },
        logo: {
            marginBottom: theme.spacing(4),
        },

    });
    const classes = useStyles();
    const authApi = useApi(provider.apiRef);
    //const configApi = useApi(configApiRef);

    const [error, setError] = useState<Error>();

    // The SignIn component takes some time to decide whether the user is logged-in or not.
    // showLoginPage is used to prevent a glitch-like experience where the sign-in page is
    // displayed for a split second when the user is already logged-in.
    const [showLoginPage, setShowLoginPage] = useState<boolean>(false);

    // User was redirected back to sign in page with error from auth redirect flow
    const [searchParams, _setSearchParams] = useSearchParams();
    const errorParam = searchParams.get('error');

    type LoginOpts = { checkExisting?: boolean; showPopup?: boolean };
    const login = async ({ checkExisting, showPopup }: LoginOpts) => {
        try {
            let identityResponse: BackstageIdentityResponse | undefined;
            if (checkExisting) {
                // Do an initial check if any logged-in session exists
                identityResponse = await authApi.getBackstageIdentity({
                    optional: true,
                });
            }

            // If no session exists, show the sign-in page
            if (!identityResponse && (showPopup || auto) && !errorParam) {
                // Unless auto is set to true, this step should not happen.
                // When user intentionally clicks the Sign In button, autoShowPopup is set to true
                setShowLoginPage(true);
                identityResponse = await authApi.getBackstageIdentity({
                    instantPopup: true,
                });
                if (!identityResponse) {
                    throw new Error(
                        `The ${provider.title} provider is not configured to support sign-in`,
                    );
                }
            }

            if (!identityResponse) {
                setShowLoginPage(true);
                return;
            }

            const profile = await authApi.getProfile();
            onSignInSuccess(
                UserIdentity.create({
                    identity: identityResponse.identity,
                    authApi,
                    profile,
                }),
            );
        } catch (err: any) {
            // User closed the sign-in modal
            setError(err);
            setShowLoginPage(true);
        }
    };

    useMountEffect(() => {
        if (errorParam) {
            setError(new Error(errorParam));
        }
        login({ checkExisting: true });
    });

    return showLoginPage ? (
        <div className={classes.container} style={{ backgroundColor: theme.palette.background.default }}>



            <RareDevLogo height="40px" className={classes.logo} />
            <Button
                color="primary"
                variant="contained"
                onClick={() => {
                    login({ showPopup: true });
                }}
            >
                Sign In
            </Button>



            {error &&
                error.name !== 'PopupRejectedError' &&
                (ErrorComponent ? (
                    <ErrorComponent error={error} />
                ) : (
                    <Typography variant="body1" color="error">
                        {error.message}
                    </Typography>
                ))}




        </div>
    ) : (
        <Progress />
    );
};